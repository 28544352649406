<template>
  <div class="logo">
    <img src="../assets/pwn-youtube_blanc.png" alt="logo pwn-youtube" />
  </div>
  <div class="text">
    <h1>Download YouTube videos and playlists for free, anytime, anywhere</h1>
  </div>
  <div
    v-if="
      Object.values(listDownload).length &&
      !Object.values(this.listDownload).some(
        (download) => download.status !== 'progress'
      )
    "
    class="zone_load"
  >
    <span class="loader"></span>
  </div>
  <div class="formulaire">
    <input
      class="input_form"
      type="text"
      v-model="url_youtube"
      placeholder="Link of the YouTube video to download"
    />
    <div class="grille_button">
      <button
        class="btn btn_audio"
        role="button"
        @click="download_youtube('audio')"
        :disabled="url_youtube === ''"
      >
        <i class="fa-solid fa-file-audio"></i> Download audio
      </button>
      <button
        class="btn btn_video"
        role="button"
        @click="download_youtube('video')"
        :disabled="url_youtube === ''"
      >
        <i class="fa-solid fa-file-video"></i> Download video
      </button>
    </div>
    <div v-if="miniature != ''" class="miniature_youtube">
      <img :src="miniature" alt="" referrerpolicy="no-referrer" />
    </div>
    <div class="text" v-if="Object.values(listDownload).length">
      <!-- <div>
          If the download does not start automatically, click on the links below:
          </div> -->
      <ul>
        <li v-for="(download, index) in listDownload" :key="index">
          <span v-if="download.status !== 'progress'">{{
            download.status
          }}</span
          ><progress
            v-if="download.status === 'progress'"
            :max="download.total"
            :value="download.progress"
          />&nbsp;{{ download.name
          }}<button
            v-if="download.status === 'progress'"
            @click="cancel(index)"
          >
            cancel
          </button>
        </li>
      </ul>
    </div>
    <div class="text">
      <div class="protips">
        Pro tips : add <span class="white">'pwn-'</span> before youtube url:
        <span class="white"
          >https://<span class="gras">pwn-</span>youtube.com/watch?v=xxx</span
        >
      </div>
    </div>
    <div class="text">
      <p>
        Are you looking for a simple and effective way to retrieve YouTube
        videos to watch offline or use in your own projects? Look no further!
      </p>
      <p>
        Our YouTube video retrieval tool allows you to easily and quickly
        download high-quality videos from YouTube with just one click.
      </p>
      <p>
        We take pride in providing a reliable and user-friendly service for
        anyone looking to retrieve YouTube videos. Try our YouTube video
        retrieval tool today.
      </p>
    </div>
  </div>
</template>

<script>
const { io } = require("socket.io-client");

let download_playlist;
const socket = io(process.env.VUE_APP_API_URL);

console.log(socket);
export default {
  name: "HelloWorld",
  data: () => ({
    url_youtube: "",
    id_youtube: "",
    id_list: "",
    listDownload: {},
  }),

  created() {
    this.getlink();
    socket.on("start", (data) => {
      console.log(this);
      // this.popup.close();
      this.listDownload[data.youtubeToken] = {
        name: data.name,
        progress: 0,
        status: "progress",
        total: data.total,
      };
    });
    socket.on("progress", (data) => {
      this.listDownload[data.youtubeToken].progress = data.progress;
    });
    socket.on("status", (data) => {
      this.listDownload[data.youtubeToken].status = data.status;
    });
  },
  computed: {
    // a computed getter
    miniature() {
      if (this.url_youtube !== "") {
        const youtubeToken = new URLSearchParams(
          this.url_youtube.split("?")[1]
        ).get("v");
        return "https://i.ytimg.com/vi/" + youtubeToken + "/hq720.jpg";
      }
      return "";
    },
  },
  methods: {
    download_youtube(type) {
      this.in_download = true;
      const data = {};

      const youtubeToken = new URLSearchParams(
        this.url_youtube.split("?")[1]
      ).get("v");
      console.log(youtubeToken);
      download_playlist = false;

      // if(parts[0] != undefined){
      //     this.id_youtube = parts[0].replace("v=", "");
      // }
      // if(parts[1] != undefined){
      //     this.id_list = parts[1].replace("list=", "");
      //     if(this.id_list != 'null'){
      //        download_playlist = true;
      //     }
      // }

      data.id_list = "";
      data.id_youtube = "";
      if (download_playlist) {
        data.id_list = this.url_youtube;
      } else {
        data.id_youtube = this.url_youtube;
      }

      data.type = type;
      // if (data) {
      // 	const xhr = new XMLHttpRequest();
      //           this.listDownload = [];
      // 	xhr.open('POST', url);
      // 	xhr.setRequestHeader('Content-Type', 'application/json');
      //           xhr.addEventListener('load', () => {
      //               this.in_download = false;
      //               const retour = JSON.parse(xhr.responseText);
      //               retour.forEach(element => {
      //                   this.listDownload.push(element);
      //                   window.location.href = element;
      //
      //               });
      //           });
      // 	xhr.send(JSON.stringify(data));
      // }
      if (socket) {
        window.open(
          `${process.env.VUE_APP_API_URL}?youtubeToken=${youtubeToken}&socketId=${socket.id}&type=${type}`,
          "_blank"
        );
      } else {
        window.open(
          `${process.env.VUE_APP_API_URL}?youtubeToken=${youtubeToken}&type=${type}`,
          "_blank"
        );
      }
    },
    getlink() {
      const params = new URLSearchParams(window.location.search);
      if (params.get("v")) {
        if (params.get("list")) {
          this.url_youtube =
            "https://www.youtube.com/watch?v=" +
            params.get("v") +
            "&list=" +
            params.get("list");
        } else {
          this.url_youtube =
            "https://www.youtube.com/watch?v=" + params.get("v");
        }
        this.miniature =
          "https://i.ytimg.com/vi/" + params.get("v") + "/hq720.jpg";
      }
    },
    cancel(youtubeToken) {
      socket.emit("cancel", { youtubeToken });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.text {
  margin-top: 5rem;
}

.logo img {
  width: 80%;
}
.input_form {
  padding: 1rem;
  font-size: 1.3rem;
  width: -webkit-fill-available;
}
.grille_button {
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
}

.btn {
  color: white;
  border-radius: 4rem;
  padding: 15px 30px;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  transition-duration: 400ms;
}
.btn_audio {
  background-color: #0963df;
}
.btn_video {
  background-color: #e8190f;
}
.btn:hover {
  transform: scale(1.05);
}
.btn_audio:disabled {
  background-color: #2e4669;
  cursor: auto;
  transform: none;
}
.btn_video:disabled {
  background-color: #703330;
  cursor: auto;
  transform: none;
}
.loader {
  box-sizing: border-box;
  display: inline-block;
  width: 50px;
  height: 80px;
  border-top: 5px solid #fff;
  border-bottom: 5px solid #fff;
  position: relative;
  background: linear-gradient(#ff3d00 30px, transparent 0) no-repeat;
  background-size: 2px 40px;
  background-position: 50% 0px;
  animation: spinx 5s linear infinite;
}
.loader:before,
.loader:after {
  content: "";
  width: 40px;
  left: 50%;
  height: 35px;
  position: absolute;
  top: 0;
  transform: translatex(-50%);
  background: rgba(255, 255, 255, 0.4);
  border-radius: 0 0 20px 20px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: 0 0px;
  animation: lqt 5s linear infinite;
}
.loader:after {
  top: auto;
  bottom: 0;
  border-radius: 20px 20px 0 0;
  animation: lqb 5s linear infinite;
}
.protips {
  font-weight: 400;
  background-color: #e81a0f14;
  border-radius: 7px;
  padding: 5px;
  font-size: 1.2rem;
  color: #6a6a6a;
}
.white {
  color: #fff;
}
.gras {
  font-weight: 700;
  color: #e81a0f;
}
@keyframes lqt {
  0%,
  100% {
    background-image: linear-gradient(#ff3d00 40px, transparent 0);
    background-position: 0% 0px;
  }
  50% {
    background-image: linear-gradient(#ff3d00 40px, transparent 0);
    background-position: 0% 40px;
  }
  50.1% {
    background-image: linear-gradient(#ff3d00 40px, transparent 0);
    background-position: 0% -40px;
  }
}
@keyframes lqb {
  0% {
    background-image: linear-gradient(#ff3d00 40px, transparent 0);
    background-position: 0 40px;
  }
  100% {
    background-image: linear-gradient(#ff3d00 40px, transparent 0);
    background-position: 0 -40px;
  }
}
@keyframes spinx {
  0%,
  49% {
    transform: rotate(0deg);
    background-position: 50% 36px;
  }
  51%,
  98% {
    transform: rotate(180deg);
    background-position: 50% 4px;
  }
  100% {
    transform: rotate(360deg);
    background-position: 50% 36px;
  }
}
p {
  font-size: 1.1rem;
}
.miniature_youtube {
  width: 12rem;
  /* display: list-item; */
  margin: auto;
  margin-top: 1rem;
}
.miniature_youtube img {
  border-radius: 1rem;
  width: inherit;
  filter: drop-shadow(2px 4px 6px black);
}
a {
  color: white;
}
ul {
  text-align: left;
  display: flex;
  align-content: space-between;
  flex-direction: column;
  list-style: auto;
}
li {
  margin: 0.5rem 0rem;
}
</style>
