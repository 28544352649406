<template>
  <HelloWorld />
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";

export default {
  name: "App",
  components: {
    HelloWorld,
  },
};
</script>

<style>
body {
  text-align: center;
  margin: auto;
  color: white;
  font-family: "Roboto", sans-serif;
  background-color: #212121;
}
.container {
  max-width: 45rem;
  margin: auto;
  padding: 1rem;
}
</style>
